function Footer() {
  return (
    <footer className="footer">
      <p className="footer__text">
        &copy; 2021 Around The U.S.
      </p>
    </footer>
  );
}

export default Footer;